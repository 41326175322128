export const data = {
  instructions: "To short your link paste it below",
  customBH: "If you want a custom URL check before if it exist",
  validURL: "Your URL is valid",
  invalidURL: "Your URL is already in use",
  shortButton: "SHORT",
  moreOptsButton: "MORE OPTIONS",
  wtoDo: "What can you do here?",
  wtoDoText: <>On this website as the name says, you can short
    any kind of URL and you can even personalize your own URL for free.</>,
  about: "About this project",
  aboutText: <>This project was made for fun and without any profit intention.
    <br /> <br /> The app still in development so if you find any issue you can report it at the github respository
    . Some interesting features will be added soon.
    <br /> <br /> I hope people could find this app useful</>,
}
